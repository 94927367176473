<template>
  <div>
    <el-row :gutter="40">
      <el-col
        v-for="service in services"
        :key="service.name"
        :span="24"
        :lg="8"
      >
        <router-link :to="{ name: 'Services', hash: service.scrollTo }">
          <div class="service mb-4">
            <img
              :src="require(`@/assets/images/defaults/${service.image}`)"
              alt=""
              class="service__image"
            />
            <div class="service__name text-center">
              {{ service.name }}
            </div>
            <div class="paragraph text-center">
              {{ service.description }}
            </div>
          </div>
        </router-link>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import { labels } from "@/common";

  export default {
    name: "ServicesGrid",

    props: {
      services: {
        type: Array,
        required: true,
      },
    },

    data() {
      return {
        labels,
      };
    },
  };
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/main.scss";

  .service {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 400px;

    padding: 2rem 1rem;
    background: white;
    text-align: left;
    cursor: pointer;

    transition: all 200ms ease-in;

    &__image {
      height: 100px;
      width: 100px;
      color: $tertiary-color;
      margin-bottom: 2rem;
      transform: scale(0.9);
      transition: all 200ms linear;
    }

    &__name {
      font-size: 1.75rem;
      font-weight: 700;
      line-height: 2.125rem;
      letter-spacing: 1px;
      width: 100%;
      padding: 2rem;
      color: $primary-color;
      text-transform: capitalize;
    }

    &:hover &__name {
      color: $primary-color;
    }

    &:hover &__image {
      transform: scale(1);
    }
  }
</style>
