<template>
  <with-parallax-heading>
    <template #title>
      {{ labels.MENU_EXPERTISE }}
    </template>

    <template #caption> {{ labels.PH_SERVICES_QUOTE }} </template>

    <div class="container default-padding">
      <div class="primary-heading text-center">
        {{ labels.LBL_SERVICE_MAIN_TITLE }}
      </div>

      <div class="paragraph text-center mb-6">
        {{ labels.PH_SERVICES }}
      </div>

      <services-grid :services="serviceCards" />
    </div>

    <div v-if="!loading">
      <div v-if="services && services.length > 0">
        <div
          v-for="service in services"
          :key="service.id"
          :id="service.id"
          class="service"
        >
          <div class="service__detail container default-padding">
            <img
              v-if="service.image_url"
              :src="service.image_url"
              :alt="service.name"
              class="service__image"
            />

            <div class="service__title primary-heading">{{ service.name }}</div>

            <div class="paragraph" v-html="service.description"></div>
          </div>
        </div>
      </div>
    </div>

    <div v-else class="container default-padding">
      {{ `${labels.LBL_LOADING}...` }}
    </div>

    <div class="tools-and-tech">
      <div class="container default-padding">
        <tools-and-tech />
      </div>

      <div class="text-center mb-6" style="font-size: 1.4rem">
        Interested in starting a project with us?
        <router-link to="/contact-us">
          <span class="primary--text">Request for a Quote</span>
        </router-link>
      </div>
    </div>
  </with-parallax-heading>
</template>

<script>
  import { labels, services } from "@/common";
  import { mapState } from "vuex";
  import WithParallaxHeading from "@/components/WithParallaxHeading";
  import ServicesGrid from "@/components/ServicesGrid";
  import ToolsAndTech from "@/components/ToolsAndTech.vue";

  export default {
    name: "Services",

    components: {
      WithParallaxHeading,
      ServicesGrid,
      ToolsAndTech,
    },

    data() {
      return {
        labels,
        serviceCards: [...services],
      };
    },

    computed: {
      ...mapState({
        loading: (state) => state.isBusy,
        services: (state) => state.expertise.services.data,
      }),
    },

    created() {
      this.loadServices();
    },

    methods: {
      loadServices() {
        this.$store.dispatch("expertise/getAllServices", this.params);
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/main.scss";

  .service {
    padding: 3rem 0;

    min-height: 400px;

    &__image {
      height: 350px;
      width: 50%;
      margin-bottom: 1rem;
      object-fit: cover;
    }

    &__title {
      margin-top: -0.5rem;
      font-size: 1.625rem;
      color: $primary-color;
      text-transform: uppercase;
    }

    &:nth-child(even) {
      background: $background-color;
      color: white;

      .primary-heading {
        color: white;
      }
      .service__image {
        float: right;
        margin-left: 2rem;
      }
    }

    &:nth-child(odd) {
      .service__image {
        float: left;
        margin-right: 2rem;
      }
    }
  }

  .install-req {
    background: $background-color;
    padding: 2rem 0;
  }

  @media only screen and (max-device-width: 768px) {
    .service {
      &__title {
        text-align: center;
      }

      &__image {
        width: 100%;
        margin: 0;
      }
    }
  }
</style>
