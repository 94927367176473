export const useScrollContent = function() {
  let scrollWithOffset = ({ target, offset }) => {
    let element = document.querySelector(target);
    let headerOffset = offset;
    let elementPosition = element.getBoundingClientRect().top;
    let offsetPosition = elementPosition - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  };

  let scrollToTarget = (target) => {
    let element = document.querySelector(target);

    element.scrollIntoView({
      behavior: "smooth",
    });
  };

  return { scrollWithOffset, scrollToTarget };
};

export default useScrollContent;
