<template>
  <div style="width: 100%">
    <div class="heading-wrapper">
      <div
        class="parallax"
        :style="{
          backgroundImage: `url(${backgroundImage})`,
        }"
      >
        <div class="overlay side-header">
          <div class="container heading">
            <!-- Parallax Heading  -->
            <div class="heading__primary">
              <slot name="title"></slot>
            </div>

            <!-- Parallax Caption  -->
            <div class="heading__caption">
              <slot name="caption"></slot>
            </div>

            <!-- Scroll Icon -->
            <div class="heading__scroller" @click="scollToContent">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                class="bi bi-chevron-compact-down"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M1.553 6.776a.5.5 0 0 1 .67-.223L8 9.44l5.776-2.888a.5.5 0 1 1 .448.894l-6 3a.5.5 0 0 1-.448 0l-6-3a.5.5 0 0 1-.223-.67z"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="page-content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
  import useScrollContent from "@/composables/useScrollContent";

  const scroll = useScrollContent();

  export default {
    name: "WithParallaxHeading",

    props: {
      backgroundImage: {
        type: String,
        default: `${require("@/assets/images/defaults/slider6-2000x1334-96.jpg")}`,
      },
    },

    methods: {
      scollToContent() {
        scroll.scrollWithOffset({ target: ".page-content", offset: 60 });
      },
    },

    setup() {
      let scollToContent = () => {
        scroll.scrollWithOffset({ target: ".page-content", offset: 60 });
      };

      return { scollToContent };
    },
  };
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/main.scss";

  .heading-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: $parallax-height;
  }

  .parallax {
    height: 100%;
    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .overlay {
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
  }

  .heading {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 1.5rem 4rem;
    color: white;

    &__primary,
    &__caption {
      text-align: center;
    }

    &__primary {
      font-weight: 600;
      letter-spacing: 3px;
      font-size: 2.15rem;
      text-transform: uppercase;
    }

    &__caption {
      font-size: 1.25rem;
    }

    &__scroller {
      margin-top: 2rem;
      cursor: pointer;
      animation: bounce 1s infinite;

      svg {
        flex-shrink: 0;
        height: 50px;
        width: 50px;
      }
    }
  }

  .page-content {
    margin-top: $parallax-height;
    padding-top: 0.5rem;
    width: 100%;
  }

  /* Turn off parallax scrolling for all tablets and phones. Increase/decrease the pixels if needed */
  @media only screen and (max-device-width: 768px) {
    .parallax {
      background-attachment: scroll;
    }

    .heading {
      padding: 1rem;

      &__primary {
        font-size: 2.15rem;
        text-transform: uppercase;
      }

      &__caption {
        font-size: 1.75rem;
        width: 100%;
      }
    }
  }
</style>
